import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    contactInfo: [
      {
        link: "https://www.facebook.com/tragaalmas.mezcal",
        icon: "facebook.png",
      },
      {
        link: "https://www.instagram.com/traga.almas/",
        icon: "instagram.png",
      },
      {
        link: "https://api.whatsapp.com/send?phone=5213323121591",
        icon: "whatsapp.png",
      },
    ],
    sections: {
      hero: {
        title: 'Traga <span class="h2">Almas</span>',
        description:
          "Disfruta de una experiencia mezcalera ancestral desde tu casa con nuestros cocteles a <strong>base de mezcal Ready-To-Drink</strong>",
      },
      bestiario: {
        title: 'Bestiario <span class="h3">Elemental</span>',
        description: [
          "Cuenta la leyenda que cuatro criaturas elementales <strong>fusionaron su alma con los mejores sabores frutales y un líquido ancestral conocido como mezcal.</strong>",
          "Estas creaciones son perfectas para fiestas, reuniones o cualquier evento social. (Bodas, graduaciones, networking de negocios)",
        ],
      },
      aboutUs: {
        title: "Nosotros",
        description: [
          "Somos un grupo de emprendedores de Guadalajara Jalisco, que fusiona la bebida ancestral conocida como mezcal, con exóticos sabores frutales.",
          "Creando mixología de autor digna de los dioses, para disfrutar en todas tus reuniones.",
        ],
      },
    },
    products: [
      {
        name: "Basilisco",
        details: "Bebida con mezcal cenizo sabor Fresa | Menta.",
        description: [
          "La criatura más explosiva. Fusiona los sabores rojos de la fresa y la frescura de la menta.",
          "Junto con el mezcal forman una bebida digna de los dioses. ",
        ],
        features: "295 ml. ALC.VOL. 8%",
        img: "basilisco.webp",
      },
      {
        name: "Goblin",
        details: "Bebida con mezcal cenizo sabor Toronja | Té de limón.",
        description: [
          "Fresco, ardiente y místico. Mezcla el sabor cítrico de la toronja con la suavidad del té de limón.",
          "Su complemento con el mezcal le da el tono mágico.",
        ],
        features: "295 ml. ALC.VOL. 8%",
        img: "goblin.webp",
      },
      {
        name: "Silfos",
        details: "Bebida con mezcal cenizo sabor Mandarina | Menta.",
        description: [
          "Todo un enigma y explosión en el paladar gracias a su fusión de mandarina con menta.",
          "Que con mezcal lo vuelve una experiencia extraordinaria.",
        ],
        features: "295 ml. ALC.VOL. 8%",
        img: "silfos.webp",
      },
      {
        name: "Ondina",
        details: "Bebida con mezcal cenizo sabor Maracuyá | Limón.",
        description: [
          " La más divina y mística. Su mezcla de maracuyá con té de limón, la convierte una experiencia única.",
          "Su toque de mezcal le otorga poderes más allá de lo conocido.",
        ],
        features: "295 ml. ALC.VOL. 8%",
        img: "ondina.webp",
      },
    ],
  },
  mutations: {},
  actions: {},
  modules: {},
});
