<template>
  <!-- prettier-ignore -->
  <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-4 px-3 border-top border-secondary">
      <div class="col-md-4 d-flex align-items-center">
        <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <img src="@/assets/tya-logo.png" alt="Traga almas logo" class="logo">
        </a>
        <span class="text-muted">© {{ getYear() }} Traga Almas</span>
      </div>

      <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
        <li class="ms-3" v-for="contact, index in contactInfo" :key="index">
          <a :href="contact.link" target="_blank" class="text-muted">
            <img :src="require(`@/assets/icons/${contact.icon}`)" alt="" class="icon"></a></li>
      </ul>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  created() {},
  computed: mapState(["contactInfo"]),
  data() {
    return {};
  },
  props: {},
  methods: {
    getYear: function () {
      const d = new Date();
      return d.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #000;
}
.logo {
  width: 24px;
}
.icon {
  width: 24px;
}
</style>