<template>
  <!-- prettier-ignore -->
  <div id="ProductCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" v-for="index in products.length" :key="index" :class="index<2?'active':''" 
        data-bs-target="#ProductCarousel" :data-bs-slide-to="index-1" :aria-label="`Slide ${index}`"
        :aria-current="index<2?'true':''"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item" v-for="product, index in products" :key="index" :class="index<1?'active':''">
        <div class="container">
          <div class="carousel-caption">
            <div class="carousel-picture">
              <img :src="require(`@/assets/images/png/${product.img}`)" :alt="`${product.name}: ${product.details.toLowerCase()}`">
              <img class="seal" src="@/assets/images/png/tya-seal.svg" alt="" />
            </div>
            <div class="carousel-caption-title">
              <h2 class="h2 my-4">{{product.name}}</h2>
            </div>
            <p class="mb-4"><strong>{{product.details}}</strong></p>
            <p class="mb-4" v-for="paragraph, index in product.description" :key="index" v-html="paragraph"></p>
            <small>{{product.features}}</small>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-cta btn btn-primary text-light text-uppercase" href="http://tienda.tragaalmas.mx/" target="_blank">
      consigue aquí tu bestiario
    </a>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#ProductCarousel"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Anterior</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#ProductCarousel"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Siguiente</span>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProductCarousel",
  created() {},
  computed: mapState(["products"]),
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.carousel {
  height: 800px;
  &-indicators {
    top: -40px;
    bottom: initial;
    button {
      border-radius: 50%;
    }
  }
  &-inner {
    height: 100%;
  }
  &-item {
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    border: solid 3px rgba(200, 200, 200, 0.1);
  }
  &-caption {
    top: 0;
    right: 10%;
    left: 10%;
    h2 {
      letter-spacing: -0.095em;
    }
    p {
      font-weight: 100;
      font-size: 2.2rem;
      letter-spacing: -0.02em;
      strong {
        font-weight: 400;
      }
    }
    small {
      font-weight: 100;
    }
  }
  &-picture {
    position: relative;
    img:first-child {
      filter: drop-shadow(-15px -2px 9px #131313);
      -webkit-filter: drop-shadow(-15px -2px 9px #131313);
    }
    .seal {
      position: absolute;
      bottom: 0;
      left: calc(50% + 10px);
    }
  }
  &-cta {
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 220px;
  }
}
</style>