<template>
  <!-- prettier-ignore -->
  <div class="header container d-flex">
    <header class="d-flex justify-content-center w-100 py-3">
      <ul class="nav nav-pills align-content-center justify-content-between w-100 px-2">
        <li class="nav-item" v-for="contact, index in contactInfo" :key="index">
          <a :href="contact.link" target="_blank" class="nav-link">
            <img :src="require(`@/assets/icons/${contact.icon}`)" alt=""></a></li>
      </ul>
    </header>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Header",
  created() {},
  computed: mapState(["contactInfo"]),
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  background: rgba(5, 5, 5, 0.8);
  header {
    position: relative;
    z-index: 1;
  }
  .nav-link img {
    width: 24px;
  }
}
</style>