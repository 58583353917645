<template>
  <div class="container overflow-hidden">
    <div class="hero text-center mx-lg-auto mb-5">
      <div class="hero-title">
        <h1 class="h1 mb-4" v-html="sections.hero.title"></h1>
        <img src="@/assets/images/png/tya-seal.svg" alt="" />
      </div>
      <p class="mb-4" v-html="sections.hero.description"></p>
      <a
        href="http://tienda.tragaalmas.mx/"
        target="_blank"
        class="btn btn-primary text-light text-uppercase"
        >Comprar aquí</a
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Hero",
  created() {},
  computed: mapState(["sections"]),
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 250px;
  .hero {
    width: 274px;
    margin: auto;
    &-title {
      position: relative;
      h1 {
        letter-spacing: -0.095em;
      }
      img {
        position: absolute;
        width: 68px;
        bottom: -10px;
        right: 4px;
      }
    }
    p {
      font-weight: 100;
      font-size: 2.2rem;
      letter-spacing: -0.02em;
      strong {
        font-weight: 400;
      }
    }
  }
}
</style>