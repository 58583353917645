<template>
  <div class="home overflow-hidden">
    <Header class="header" />
    <main class="position-relative overflow-hidden">
      <Hero class="hero surface-layer" />
      <div class="snake-bg"></div>

      <div class="bestiario container surface-layer pb-5">
        <div class="text-center">
          <div class="bestiario-title">
            <img src="@/assets/images/png/runes.png" alt="bestiario symbols" />
            <h2 class="h2 mt-4 mb-5" v-html="sections.bestiario.title"></h2>
          </div>
          <p
            class="mb-5"
            v-for="(paragraph, index) in sections.bestiario.description"
            :key="index"
            v-html="paragraph"
          ></p>
        </div>
      </div>

      <div class="snake-bg"></div>

      <ProductCarousel class="product-carousel surface-layer" />

      <div class="snake-bg"></div>

      <div
        class="
          container
          overflow-hidden
          bg-linear-gradient
          text-center
          surface-layer
        "
      >
        <div class="about-us">
          <h2 class="mb-5" v-html="sections.aboutUs.title"></h2>
          <p
            class="mb-5"
            v-for="(paragraph, index) in sections.aboutUs.description"
            :key="index"
            v-html="paragraph"
          ></p>
        </div>
        <img src="@/assets/tya-logo.png" alt="traga almas logo" />
        <div class="distribuidor">
          <h3 class="mb-5">¿Te gustaría distribuir nuestras bebidas?</h3>
          <a
            href="https://lamezcalita.pedidosenlinea.mx/"
            target="_blank"
            class="btn btn-primary text-light text-uppercase"
            >Quiero distribuir</a
          >
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
// @ is an alias to /src
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Hero from "@/components/Hero";
import ProductCarousel from "@/components/ProductCarousel";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Hero,
    ProductCarousel,
  },
  computed: mapState(["sections"]),
};
</script>
<style lang="scss" scoped>
.home {
  background: linear-gradient(0deg, rgba(12, 9, 7, 0.1), rgba(12, 9, 7, 0.1)),
    linear-gradient(180deg, #141414 0%, #7a7a7a 100%);
  .header {
    z-index: 2;
  }
  .surface-layer {
    position: relative;
    z-index: 1;
  }
  .hero {
    margin-bottom: 250px;
    & + .snake-bg {
      top: 0;
      background-position-y: -70px;
    }
  }
  .bestiario + .snake-bg {
    top: 1000px;
    background-position-x: -270px;
    background-position-y: initial;
  }
  .product-carousel + .snake-bg {
    height: 115%;
  }
  .about-us {
    width: 325px;
    margin: auto;
    padding-top: 130px;
    padding-bottom: 90px;
    h2 {
      letter-spacing: -0.085em;
    }
    p {
      font-size: 2.2rem;
      font-weight: 100;
      text-align: justify;
    }
  }
  .distribuidor {
    padding-top: 90px;
    padding-bottom: 220px;
    h3 {
      letter-spacing: -0.085em;
      line-height: 0.8;
    }
  }
  .bg-linear-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }
  .bestiario {
    h2 {
      letter-spacing: -0.095em;
    }
    p {
      width: 275px;
      margin: auto;
      font-weight: 100;
      font-size: 2.2rem;
      letter-spacing: -0.02em;
      text-align: justify;
      strong {
        font-weight: 400;
      }
    }
  }
}
.snake-bg {
  position: absolute;
  max-width: 650px;
  width: 100%;
  height: 1000px;
  background-position-x: 70px;
  background-position-y: 70px;
  background-image: url("../assets/images/png/snake-bottle.png");
  background-repeat: no-repeat;
}
</style>